import { DashboardSEO } from '../components';
import { Dashboard } from '../sections';

const DashboardPage = () => {
  return (
    <>
      <DashboardSEO
        title="Painel do Cliente 2ND Market | 2ND Market"
        description="Gerencie as informações da sua conta no painel da 2ND Market."
      />
      <Dashboard />
    </>
  );
};

export default DashboardPage;
